/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
    $.fn.datepicker.defaults.startDate = "today";
    $(document).ready(function () {
        // HOME : Recherche de box
        $('.datepicker').datepicker({
            autoclose: true,
            //startDate: "today",
            language: 'fr',
        });
        // HOME : Mettre à jour les boutons en fonction des diponibilités
        $('.search-box .search-box-engine input').change(function () {
            $(this).closest('.search-box').find('.box-overlay-wait').css('display', 'flex').hide().fadeIn(300);
            $.ajax({
                url: ajaxurl,
                method: 'POST',
                data: {
                    action: 'search_boxes_all_models',
                    fields: $(this).closest('form').serializeArray()
                },
                success: function (datas) {
                    $('.search-box .box-overlay').fadeOut(300);
                    $('.search-box .search-box-models .search-box-model').removeClass('unavailable')
                    $('.search-box .search-box-models .search-box-model').each(function (key, box) {
                        if (datas.indexOf($(box).data('model-id')) < 0) {
                            $(box).addClass('unavailable');
                        }
                    })
                },
                error: function (e) {
                    console.error(e);
                    $('.search-box .box-overlay-wait').fadeOut(300);
                }
            })
        })
        $('.search-box .button-unavailable').click(function () {
            $('.search-box .box-overlay-alert .box-model-name').html($(this).closest('.search-box-model').data('model-name'));
            $('.search-box .box-overlay-alert #model').val($(this).closest('.search-box-model').data('model-id'));
            $('.search-box .box-overlay-alert #depot').val($(this).closest('form').find('[name=depot]').val());
            $('.search-box .box-overlay-alert [name=wp_nonce]').val(ajaxnonce);
            $('.search-box .box-overlay-alert').css('display', 'flex').hide().fadeIn(300);
            $('.search-box .box-overlay-alert #email').focus();
        })
        $('.search-box .box-overlay-alert .button-light').click(function () {
            $('.search-box .box-overlay-alert').fadeOut(300);
        })
        $('.search-box .box-overlay-alert form').on('submit', function (e) {
            $.post(ajaxurl, $(this).serializeArray(), function () {
                $('.search-box .box-overlay-alert').fadeOut(300);
            })
            e.preventDefault();
        })

        // ACCOUNT : Voir un box sur la carte du dépot
        $('[data-lightbox]').click(function (e) {
            console.log("click lightbox link");
            $($(this).data('lightbox')).addClass('active')
        })
        $('.lightbox-overlay').click(function (e) { if (e.target == this) { $(this).removeClass('active'); } });
        $('.account-open-map-box').click(function (e) {
            account_show_map($(this).data('map-url'), $(this).data('map-box'));
            e.preventDefault()
        })

        // ACCOUNT : Modifier la date de libération prévisionnelle
        $('.liberation_box-openner').click(function (e) {
            $('#liberation-contrat_id').val($(this).closest('.contract').data('contrat-id'));
            $('#new_liberation').val('');
        })


        // ACCOUNT: Demander le changement d'offres
        $('.change_box-openner').click(function (e) {
            $('#change_model-contrat_id').val($(this).closest('.contract').data('contrat-id'));
        })

        // ACCOUNT : Résilier un contrat
        $('.stop_contrat-openner').click(function (e) {
            $('#stop_contrat-contrat_id').val($(this).closest('.contract').data('contrat-id'));
        })


        $('.stop-contrat-box form').submit(function (e) {
            e.preventDefault();
            $.ajax({
                url: ajaxurl,
                type: 'POST',
                data: {
                    action: 'cloture_contrat',
                    contrat_id: $("#stop_contrat-contrat-id").val(),
                    date: $("#stop_contrat-date").val()
                },
                success: function (ret) {
                    alert(ret.data.message);
                    document.location.href = document.location.href;
                }
            })
        })

        // CART : Supprimer un élément du panier
        $('.table.cart .remove').click(function (e) {
            var tr = $(this).closest('tr');
            $.ajax({
                url: ajaxurl,
                type: 'POST',
                data: {
                    action: 'delete_contrat',
                    contrat_id: tr.data('contrat-id')
                },
                success: function (ret) {
                    if (ret == 'true') {
                        tr.fadeOut(300, function () { tr.remove(); recalc_cart_totals(); });
                    }
                }
            })

            e.preventDefault()
        })

        // Calcul des tarifs au chargement
        if ($('.table.cart .cart-product-quantity').length) {
            recalc_cart_totals();
        }
    });
    $('.table.cart .cart-product-quantity').change(function () { recalc_cart_totals(); });
    $('.table.cart .assurance-checkbox').change(function () { recalc_cart_totals(); });

    /**
     * Positionne un dépot sur la map du dépot et l'affiche dans une lightbox
     * @param {string} map_url : URL de l'image SVG du plan du dépot
     * @param {string} box_id  : ID du rect que représente ce box sur la map SVG
     */
    function account_show_map(map_url, box_id) {
        $.ajax({
            url: map_url,
            success: function (datas) {
                $('.account-map-box-overlay .account-map-box .inner').html($(datas).find('svg'));
                $('.account-map-box-overlay .account-map-box svg [data-name=' + box_id + ']').addClass('active');
                $('.account-map-box-overlay').addClass('active');
            }
        });
        //$('.account-map-box-overlay .account-map-box').load($(this).data('map-url'));
        return false;

    }

    /**
     * Recalcule les totaux du painer
     */
    function recalc_cart_totals() {
        var total = 0;
        var formater = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' });
        $('.table.cart').find('tr.cart_item').each(function () {
            if (parseInt($(this).find('.cart-product-quantity input').val()) < 1) {
                $(this).find('.cart-product-quantity input').val(1);
            }
            if (parseInt($(this).find('.cart-product-quantity input').val()) > 12) {
                $(this).find('.cart-product-quantity input').val(12);
            }
            var factor = parseInt($(this).find('.cart-product-quantity input').val());
            var unitPrice = parseFloat($(this).data('unit-price'));
            if ($(this).find('.assurance-checkbox').prop('checked')) {
                $(this).find('.cart-product-price .amount').html(formater.format(unitPrice) + '<br>+' + formater.format(parseFloat($(this).data('assurance-price'))))
                unitPrice = unitPrice + parseFloat($(this).data('assurance-price'));
            }
            else {
                $(this).find('.cart-product-price .amount').html(formater.format(unitPrice))
            }
            var subtotal = unitPrice * factor;
            $(this).find(".cart-product-subtotal .amount").html(formater.format(subtotal));
            total = total + subtotal;
        })
        $('.total-ht .amount').html(formater.format(total * 0.8))
        $('.total-tva .amount').html(formater.format(total * 0.2))
        $('.total-ttc .amount strong').html(formater.format(total));

        if ($('.table.cart').find('.assurance-checkbox:checked').length) {
            $('.cassur-label').show();
            $('.cassur-checkbox').attr('required', true);
        }
        else {
            $('.cassur-label').hide();
            $('.cassur-checkbox').attr('required', false);
        }

        if (total == 0) {
            document.location.href = document.location.href;
        }
    }

})(jQuery); // Fully reference jQuery after this point.

